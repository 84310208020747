import { gql } from "@apollo/client";

export const updateBookQuery = gql`
mutation updateBookQuery($book: BookDTOInput!) {
  updateBook(book: $book) {
    id
    title
    author
    iSBN
    copyright
    published
    illustrator
    publisher
    status
    category
    isbn: iSBN
    notes {
      id
      schoolId
      schoolName
      quantity
      notes
      status
    }
  }
}
`;
