import { Typography } from '@mui/material';
import React from 'react';
import Logo from "../logo.png";
import { Layout } from './Layout';

export function AboutPage() {
  return <Layout>
    <div
     style={{ 
        display: 'flex',
        flexDirection: 'row', 
        justifyContent: 'center',
        marginTop: '20px'
      }}
    >
      <div 
        style={{
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div>
          <Typography
            style={{
              fontStyle: 'italic',
              textAlign: 'center'
            }}
            variant="h5"
          >
            Developed by
          </Typography>
          <img
            alt="Olive Tree Logo"
            src={Logo}
            style={{
              height: '125px',
              marginBottom: '20px'
            }}
          />
          <Typography
            variant="h6"
            style={{
              textAlign: 'center'
            }}
          >
            For support contact us at:
          </Typography>
          <Typography
            variant="subtitle1"
            style={{
              textAlign: 'center'
            }}
          >
            (717) 949-7252
          </Typography>
          <Typography
            variant="subtitle1"
            style={{
              textAlign: 'center'
            }}
          >
            <a href="https://t.me/OliveTreeSupportBot">@OliveTreeSupportBot</a>
          </Typography>
          <Typography
            variant="subtitle1"
            style={{
              textAlign: 'center'
            }}
          >
            <a href="mailto:support@olivetree.software">support@olivetree.software</a>
          </Typography>
          <Typography
            variant="subtitle1"
            style={{
              textAlign: 'center'
            }}
          >
            Developed in part with <a href="https://stauffer.enterprises">Stauffer Enterprises</a>.
          </Typography>
        </div>
      </div>
    </div>
  </Layout>;
}
