import { Layout } from '../../Layout';
import ReportViewer from './ReportViewer';

export function ReportViewerPage(props: any) {
  return <Layout>
    <ReportViewer reportId={props?.match?.params?.id}></ReportViewer>
  </Layout>;
}

export default ReportViewerPage;
