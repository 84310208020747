import { useMutation, useQuery } from '@apollo/client';
import { Home, Save } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import {
  Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, useMediaQuery
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { AppContext } from '../../../Context';
import { getBook } from '../../../queries/get/getBook';
import { getSchoolsMissingBookQuery } from '../../../queries/get/getSchoolsMissingBookQuery';
import { getSchoolsOnBookQuery } from '../../../queries/get/getSchoolsOnBook';
import { GetBook } from '../../../queries/get/types/GetBook';
import { GetSchoolsMissingBook, GetSchoolsMissingBookVariables } from '../../../queries/get/types/GetSchoolsMissingBook';
import { GetSchoolsOnBook, GetSchoolsOnBookVariables } from '../../../queries/get/types/GetSchoolsOnBook';
import { addBookToSchoolQuery } from '../../../queries/mutations/addBookToSchool';
import { addBookToSchool, addBookToSchoolVariables } from '../../../queries/mutations/types/addBookToSchool';
import { Layout } from '../../Layout';

function ViewBookPage(props: any) {
  const bigScreen = useMediaQuery('(min-width:600px)');
  const { state } = useContext(AppContext);
  const history = useHistory();

  const { loading, data: book } = useQuery<GetBook>(getBook, {
    variables: {
      id: props?.match?.params?.id ?? ""
    }
  });

  const [open, setOpen] = React.useState(false);
  const [schoolId, setSchoolId] = useState<string | null>(null);
  const [copiedSchoolId, setCopiedSchoolId] = useState<string | null>(null);

  const { data: schoolsMissingBook } = useQuery<GetSchoolsMissingBook, GetSchoolsMissingBookVariables>(getSchoolsMissingBookQuery, 
    {
      variables: {
        bookId: props?.match?.params?.id ?? ""
      },
      fetchPolicy: 'cache-and-network'
    });

    const { data: schoolsOnBook } = useQuery<GetSchoolsOnBook, GetSchoolsOnBookVariables>(getSchoolsOnBookQuery, 
      {
        variables: {
          id: props?.match?.params?.id ?? ""
        },
        fetchPolicy: 'cache-and-network'
      });

  const [assignBookToSchool, { loading: addingBook }] = useMutation<addBookToSchool, addBookToSchoolVariables>(addBookToSchoolQuery, {
    variables: {
      bookId: props?.match?.params?.id ?? "",
      schoolId: schoolId ?? "",
      copyFromId: copiedSchoolId
    },
    refetchQueries: [getBook],
    onCompleted: () => {
      setSchoolId(null);
      setOpen(false);
    }
  });

  return <Layout
    backButton={true}
    options={
      !bigScreen && (state.admin || state.editor) ?
        <IconButton
          style={{ color: 'white' }}
          onClick={() => history.push('/books/' + props?.match?.params?.id)}
        >
          <EditIcon />
        </IconButton> : <></>
    }
  >
    {
      <div style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        marginTop: "20px"
      }}>
        <Card style={{
          height: "fit-content",
          maxWidth: '700px',
          width: '100%'
        }}>
          <CardContent>
            {
              loading ? <>
                <Skeleton width={400} height={55}></Skeleton>
                <Skeleton width={670} height={65}></Skeleton>
                <div
                  style={{
                    display: 'flex'
                  }}
                >
                  <Skeleton width={320} height={65}></Skeleton>
                  <Skeleton width={320} height={65} style={{ marginLeft: '30px' }}></Skeleton>
                </div>
                <div
                  style={{
                    display: 'flex'
                  }}
                >
                  <Skeleton width={320} height={65}></Skeleton>
                  <div
                    style={{
                      display: 'flex',
                      marginLeft: '30px'
                    }}
                  >
                    <Skeleton width={160} height={65}></Skeleton>
                    <Skeleton width={160} height={65} style={{ marginLeft: '30px' }}></Skeleton>
                  </div>
                </div>
                <Skeleton width={160} height={65}></Skeleton>
                <div
                  style={{
                    display: 'flex'
                  }}
                >
                  <Skeleton width={320} height={65}></Skeleton>
                  <div
                    style={{
                      display: 'flex',
                      marginLeft: '30px'
                    }}
                  >
                    <Skeleton width={160} height={65}></Skeleton>
                    <Skeleton width={160} height={65} style={{ marginLeft: '30px' }}></Skeleton>
                  </div>
                </div>
                <Skeleton width={670} height={65}></Skeleton>
              </> :
              <>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                }}>
                  <Box display="flex" justifyContent="space-between">
                    <Box>
                      <Typography variant='h5'>{book?.book?.title === "" || book?.book?.title === null ? "(no title)" : book?.book?.title}</Typography>
                      {
                        book?.book?.author && 
                        <Typography variant='subtitle1'>by {book?.book?.author}</Typography>
                      }
                      {
                        book?.book?.illustrator !== "" && book?.book?.illustrator !== null ?
                          <Typography>Illustrated by {book?.book?.illustrator}</Typography> : <></>
                      }
                      {
                        book?.book?.publisher !== "" && book?.book?.publisher !== null ?
                          <Typography>Published by {book?.book?.publisher}</Typography> : <></>
                      }
                      {
                        book?.book?.copyright !== 0 && book?.book?.copyright !== null ?
                        <Typography>©{book?.book?.copyright}</Typography> : <></>
                      }
                      {
                        book?.book?.isbn !== "" && book?.book?.isbn !== null ?
                          <Tooltip title="Click to go to openlibrary.org">
                            <Typography>ISBN <a target="_blank" rel="noreferrer" href={"https://openlibrary.org/search?q=" + book?.book?.isbn}>{book?.book?.isbn}</a></Typography> 
                          </Tooltip> : <></>
                      }
                      <Typography>{book?.book?.published}</Typography>
                    </Box>
                    <Box flexDirection="column" display="flex" alignItems="end">
                      {
                        bigScreen ?
                        <Button
                          onClick={() => setOpen(true)}
                          startIcon={<Home />}
                          >
                          Add Book to My School
                        </Button> : <></>
                      }
                      {
                        bigScreen ?
                        <Button
                        onClick={() => history.push('/books/' + props?.match?.params?.id)}
                          startIcon={<EditIcon />}
                          >
                          Edit Book
                        </Button> : <></>
                      }
                    </Box>
                  </Box>
                  <TableContainer component={Paper} sx={{ mt: '10px' }}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>School</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Quantity</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        loading ? <></> :
                          <>
                            {
                              book?.book?.notes?.map((schoolBook, index) => {
                                return <>
                                  <TableRow
                                    key={index + 1}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                    <TableCell sx={{ borderBottom: 'none' }}>{schoolBook.schoolName}</TableCell>
                                    <TableCell sx={{ borderBottom: 'none' }}>{initCap(schoolBook?.status ?? "")}</TableCell>
                                    <TableCell sx={{ borderBottom: 'none' }}>{schoolBook.quantity}</TableCell>
                                  </TableRow>
                                  <TableRow key={(index + 1) * -1} sx={{ borderTop: 'none' }}>
                                    <TableCell colSpan={3}><i>Notes:</i>{schoolBook.notes === '' || schoolBook.notes === null ? ' (none)' : ' ' + schoolBook.notes}</TableCell>
                                  </TableRow>
                                </>;
                              })
                            }
                          </>
                      }
                      {
                        (book?.book?.notes?.length ?? 0) === 0 ?
                          <>
                            <TableRow>
                              <TableCell colSpan={3}>There are currently no editing notes available...</TableCell>
                            </TableRow>
                          </> : <></>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              </>
            }
          </CardContent>
        </Card>
      </div>
    }
    {
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          {"Add Book to My School"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select the school below to which you would like to add this book.
          </DialogContentText>
          <FormControl fullWidth sx={{ mt: 3 }}>
            <InputLabel>School To Add</InputLabel>
            <Select
              value={schoolId}
              label="School To Add"
              onChange={(id) => setSchoolId(id.target.value)}
            >
              {schoolsMissingBook?.schoolsMissingBook?.map((b) => (
                <MenuItem value={b.id}>{b.shortName}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <DialogContentText sx={{ mt: 3 }}>
            Would you like to copy editing notes from another school? If you used another school's editing notes, please select that school below.
          </DialogContentText>
          <FormControl fullWidth sx={{ mt: 3 }}>
            <InputLabel>School To Copy From</InputLabel>
            <Select
              value={copiedSchoolId}
              label="School To Copy From"
              onChange={(id) => setCopiedSchoolId(id.target.value)}
            >
              <MenuItem value={""}>(no school)</MenuItem>
              {schoolsOnBook?.book?.notes.map((b) => (
                <MenuItem value={b.schoolId ?? ""}>{b.schoolName}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton
            autoFocus
            color="primary"
            onClick={() => assignBookToSchool()}
            loading={addingBook}
            loadingPosition="start"
            startIcon={<Save />}
            variant="contained"
          >
            Add Book to School
          </LoadingButton>
        </DialogActions>
      </Dialog>
    }
  </Layout>;
}

function initCap(s: string) {
  var result = '';
  if ((typeof (s) === 'undefined') || (s == null)) {
    return result;
  }

  s = s.toLowerCase();
  var words = s.split(' ');
  for (var i = 0; i < words.length; ++i) {
    result += (i > 0 ? ' ' : '') +
      words[i].substring(0, 1).toUpperCase() +
      words[i].substring(1);
  }
  return result;
}

export default ViewBookPage;
