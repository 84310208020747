import { useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete, Button, Card, CardContent, Divider, Fab, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Skeleton, TextField, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { useHistory } from 'react-router';
import BookDTO from '../../../models/BookDTO';
import SchoolBookDTO from '../../../models/SchoolBookDTO';
import { getAuthors, getIllustrators, getPublishers } from '../../../queries/get/autocomplete';
import { getBook } from '../../../queries/get/getBook';
import { getSchoolsQuery } from '../../../queries/get/getSchools';
import { authors } from '../../../queries/get/types/authors';
import { GetBook } from '../../../queries/get/types/GetBook';
import { GetSchools } from '../../../queries/get/types/GetSchools';
import { illustrators } from '../../../queries/get/types/illustrators';
import { publishers } from '../../../queries/get/types/publishers';
import { updateBookQuery as updateBook } from '../../../queries/mutations/types/updateBookQuery';
import { updateBookQuery } from '../../../queries/mutations/updateBook';
import { Layout } from '../../Layout';

function EditBookPage(props: any) {
  const history = useHistory();
  const[ book, setBook ] = useState<BookDTO>({} as BookDTO);
  const[updateBook] = useMutation<updateBook>(updateBookQuery, {
    variables: {
      book: book
    },
    onCompleted: () => history.push("/books")
  });
  const { loading } = useQuery<GetBook>(getBook, {
    variables: {
      id: props?.match?.params?.id ?? ""
    },
    onCompleted: (e) => {
      setBook({
        author: e.book.author,
        copyright: e.book.copyright,
        id: e.book.id,
        illustrator: e.book.illustrator,
        iSBN: e.book.iSBN,
        category: e.book.category,
        notes: e.book.notes.map((n) => {
          return {
            id: n.id,
            notes: n.notes,
            quantity: n.quantity,
            schoolId: n.schoolId,
            schoolName: n.schoolName,
            status: n.status
          } as SchoolBookDTO
        }),
        published: e.book.published,
        publisher: e.book.publisher,
        title: e.book.title
      })
    }
  });

  const { data: schools } = useQuery<GetSchools>(getSchoolsQuery);

  const textStyle = {
    width: '100%'
  }

  const { data: authorsData } = useQuery<authors>(getAuthors, {
    fetchPolicy: 'cache-and-network'
  });
  const { data: illustratorsData } = useQuery<illustrators>(getIllustrators, {
    fetchPolicy: 'cache-and-network'
  });
  const { data: publishersData } = useQuery<publishers>(getPublishers, {
    fetchPolicy: 'cache-and-network'
  });

  return <Layout
    backButton={true}
    options={
      <>
      <Tooltip title="Cancel changes and return to the book list.">
        <Button onClick={() => history.push("/books")} style={{ color: 'white' }}>Cancel</Button>
      </Tooltip>
      <Tooltip title="Save changes and return to the book list.">
        <Button onClick={() => updateBook()} style={{ color: 'white' }}>Save</Button>
      </Tooltip>
      </>
    }
  >
    {
      <div style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        marginTop: "20px"
      }}>
        <Card style={{
          height: "fit-content",
          maxWidth: '700px'
        }}>
          <CardContent>
            {
              loading || book.id === undefined ? <>
                <Skeleton width={400} height={55}></Skeleton>
                <Skeleton width={670} height={65}></Skeleton>
                <div
                  style={{
                    display: 'flex'
                  }}
                >
                  <Skeleton width={320} height={65}></Skeleton>
                  <Skeleton width={320} height={65} style={{ marginLeft: '30px' }}></Skeleton>
                </div>
                <div
                  style={{
                    display: 'flex'
                  }}
                >
                  <Skeleton width={320} height={65}></Skeleton>
                  <div
                    style={{
                      display: 'flex',
                      marginLeft: '30px'
                    }}
                  >
                    <Skeleton width={160} height={65}></Skeleton>
                    <Skeleton width={160} height={65} style={{ marginLeft: '30px' }}></Skeleton>
                  </div>
                </div>
                <Skeleton width={160} height={65}></Skeleton>
                <div
                  style={{
                    display: 'flex'
                  }}
                >
                  <Skeleton width={320} height={65}></Skeleton>
                  <div
                    style={{
                      display: 'flex',
                      marginLeft: '30px'
                    }}
                  >
                    <Skeleton width={160} height={65}></Skeleton>
                    <Skeleton width={160} height={65} style={{ marginLeft: '30px' }}></Skeleton>
                  </div>
                </div>
                <Skeleton width={670} height={65}></Skeleton>
              </> :
              <>
                <div 
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}>
                  <Typography variant='h5' style={{ marginBottom: '20px' }}>{book.title === "" || book.title === null ? "(no title)" : book.title} - Details</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        value={book.title}
                        label="Title"
                        sx={textStyle}
                        onChange={(e) => setBook({ ...book, title: e.target.value })}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        freeSolo
                        inputValue={book.author ?? ''}
                        onInputChange={(e, d) => setBook({ ...book, author: d })}
                        options={authorsData?.authors.map((option: string) => option) ?? [] as string[]}
                        renderInput={(params) =>
                          <TextField {...params}
                            label="Author"
                            sx={textStyle}
                            variant="outlined"
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        freeSolo
                        inputValue={book.illustrator ?? ''}
                        onInputChange={(e, d) => setBook({ ...book, illustrator: d })}
                        options={illustratorsData?.illustrators.map((option: string) => option) ?? [] as string[]}
                        renderInput={(params) =>
                          <TextField {...params}
                            label="Illustrator"
                            sx={textStyle}
                            variant="outlined"
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        freeSolo
                        inputValue={book.publisher ?? ''}
                        onInputChange={(e, d) => setBook({ ...book, publisher: d })}
                        options={publishersData?.publishers.map((option: string) => option) ?? [] as string[]}
                        renderInput={(params) =>
                          <TextField {...params}
                            label="Publishers"
                            sx={textStyle}
                            variant="outlined"
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={book.iSBN}
                        label="ISBN"
                        sx={textStyle}
                        onChange={(e) => setBook({ ...book, iSBN: e.target.value })}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        value={book.published}
                        label="Published"
                        fullWidth={true}
                        sx={textStyle}
                        onChange={(e) => setBook({ ...book, published: (isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)) })}
                        variant="outlined"
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        value={book.copyright}
                        label="Copyright"
                        fullWidth={true}
                        sx={textStyle}
                        onChange={(e) => setBook({ ...book, copyright: (isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)) })}
                        variant="outlined"
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        value={book.category}
                        label="Category"
                        fullWidth={true}
                        sx={textStyle}
                        onChange={(e) => setBook({ ...book, category: e.target.value })}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Typography
                    variant='h6'
                    style={{
                      marginTop: "20px",
                      marginBottom: "10px"
                    }}
                  >
                    Notes & Inventory</Typography>
                  <Card style={{
                    height: "fit-content"
                  }}>
                    <CardContent style={{ position: 'relative' }}>
                      {
                        loading ? <>
                          <div
                            style={{
                              display: 'flex'
                            }}
                          >
                            <Skeleton width={320} height={65}></Skeleton>
                            <div
                              style={{
                                display: 'flex',
                                marginLeft: '30px'
                              }}
                            >
                              <Skeleton width={160} height={65}></Skeleton>
                              <Skeleton width={160} height={65} style={{ marginLeft: '30px' }}></Skeleton>
                            </div>
                          </div>
                          <Skeleton width={635} height={65}></Skeleton>
                        </> :
                          <>
                            {
                              book.notes?.map((schoolBook: SchoolBookDTO) => (
                                <div key={schoolBook.id} style={{
                                    display: "flex",
                                    flexDirection: "column"
                                  }}
                                >
                                  <Grid container spacing={2}>
                                    <Grid item xs={5}>
                                      <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">School</InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={schoolBook.schoolId}
                                          label="School"
                                          fullWidth={true}
                                          onChange={(e) => setBook({ ...book, notes: book.notes!.map((s, i) => book.notes!.indexOf(schoolBook) === i ? {...s, schoolId: e.target.value} : s ) })}
                                        >
                                          {
                                            schools?.schools?.map((value) => {
                                              return <MenuItem value={value.id} key={value.id}>{value.shortName ?? value.name}</MenuItem>
                                            })
                                          }
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <TextField
                                        label="Quantity"
                                        value={schoolBook.quantity}
                                        disabled={schoolBook.schoolId === null || schoolBook.schoolId === ""}
                                        onChange={(e) => {
                                          if (isNaN(parseInt(e.target.value))) {
                                            setBook({ ...book, notes: book.notes!.map((s, i) => book.notes!.indexOf(schoolBook) === i ? {...s, quantity: 0} : s ) })
                                          } else {
                                            setBook({ ...book, notes: book.notes!.map((s, i) => book.notes!.indexOf(schoolBook) === i ? {...s, quantity: parseInt(e.target.value)} : s ) })
                                          }
                                        }}
                                        type="number"
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                        <Select
                                          disabled={schoolBook.schoolId === null || schoolBook.schoolId === ''}
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={schoolBook.status}
                                          label="Status"
                                          fullWidth={true}
                                          onChange={(e) => setBook({ ...book, notes: book.notes!.map((s, i) => book.notes!.indexOf(schoolBook) === i ? {...s, status: e.target.value} : s ) })}
                                        >
                                          <MenuItem value={"pending"} key={"pending"}>Pending</MenuItem>
                                          <MenuItem value={"accepted"} key={"accepted"}>Accepted</MenuItem>
                                          <MenuItem value={"rejected"} key={"rejected"}>Rejected</MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={1}
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                      }}
                                    >
                                      <Tooltip title="Remove editing notes for this school.">
                                        <IconButton onClick={() => setBook({ ...book, notes: book.notes!.filter((s) => s.id !== schoolBook.id) })}>
                                          <DeleteIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        disabled={schoolBook.schoolId === null || schoolBook.schoolId === ''}
                                        label="Notes"
                                        multiline
                                        fullWidth={true}
                                        rows={4}
                                        placeholder={"Editing notes go here..."}
                                        value={schoolBook.notes ?? ''}
                                        onChange={(e) => setBook({ ...book, notes: book.notes!.map((s, i) => book.notes!.indexOf(schoolBook) === i ? {...s, notes: e.target.value} : s ) })}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Divider
                                    style={{
                                      marginBottom: "20px",
                                      marginTop: "20px"
                                    }}
                                  />
                                </div>
                              ))
                            }
                            <Tooltip title="Add editing notes for new school.">
                              <Fab
                                onClick={(e) => setBook({ ...book, notes: book.notes!.concat({ id: uuidv4(), notes: '', quantity: 0, schoolId: '', schoolName: '', status: ''} as SchoolBookDTO) })}
                                color="primary"
                                style={{
                                  position: 'absolute',
                                  bottom: 15,
                                  right: 20
                                }}
                              >
                                <AddIcon />
                              </Fab>
                            </Tooltip>
                          </>
                      }
                    </CardContent>
                    </Card>
                </div>
              </>
            }
          </CardContent>
        </Card>
      </div>
    }
  </Layout>;
}

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
}

export default EditBookPage;
