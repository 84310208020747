import { gql } from "@apollo/client";

export const updateStylesheetQuery = gql`
mutation updateStylesheetQuery($id: String!, $name: String!, $notes: String!) {
  updateStylesheet(id: $id, name: $name, notes: $notes) {
    id
    added
    name
    notes
  }
}
`;
