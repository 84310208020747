import { gql } from "@apollo/client";

export const updateReportQuery = gql`
mutation updateReportQuery($id: String!, $name: String!, $notes: String!) {
  updateReport(id: $id, name: $name, notes: $notes) {
    id
    name
    notes
  }
}
`;
