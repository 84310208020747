import { State } from './Context';

export type Actions =
  | { type: 'set_page_size'; pageSize: number }
  | { type: 'set_book_count'; count: number }
  | { type: 'set_page'; page: number }
  | { type: 'set_book_ids'; bookIds: string[] }
  | { type: 'set_search'; search: string }
  | { type: 'set_editor'; editor: boolean }
  | { type: 'set_admin'; admin: boolean }
  | { type: 'set_viewer'; viewer: boolean }

export const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case 'set_page_size':
      localStorage.setItem("pageSize", JSON.stringify({ pageSize: action.pageSize }));
      return {
        ...state,
        pageSize: action.pageSize
      }
    case 'set_book_count':
      localStorage.setItem("bookCount", JSON.stringify({ count: action.count }));
      return {
        ...state,
        bookCount: action.count
      }
    case 'set_page':
      localStorage.setItem("pageIndex", JSON.stringify({ page: action.page }));
      return {
        ...state,
        pageIndex: action.page
      }
    case 'set_book_ids':
      return {
        ...state,
        bookIds: action.bookIds
      }
    case 'set_search':
      return {
        ...state,
        search: action.search
      }
    case 'set_admin':
      return {
        ...state,
        admin: action.admin
      }
    case 'set_viewer':
      return {
        ...state,
        viewer: action.viewer
      }
    case 'set_editor':
      return {
        ...state,
        editor: action.editor
      }
  }
}
