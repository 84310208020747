import { gql } from "@apollo/client";

export const getUserPrivilegesQuery = gql`
  query getUserPrivileges($username: String!) {
    userPrivileges(username: $username) {
      admin
      editor
      viewer
    }
  }
`;
