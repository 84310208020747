import { useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Backdrop, Button, CircularProgress, Dialog,
  DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItemButton, TextField, Tooltip, Typography, useMediaQuery
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useState } from 'react';
import { useHistory } from 'react-router';
import Report from '../../../models/Report';
import { getReportsQuery } from '../../../queries/get/getReports';
import { GetReports } from '../../../queries/get/types/GetReports';
import { removeReportsQuery } from '../../../queries/mutations/removeReports';
import { updateReportQuery } from '../../../queries/mutations/updateReport';
import { Layout } from '../../Layout';

export function ReportsPage() {
  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [editReport, setEditReport] = useState<Report | null>(null);
  const bigScreen = useMediaQuery('(min-width:600px)');
  const history = useHistory();

  const { data, refetch } = useQuery<GetReports>(getReportsQuery);

  const [deleteReports] = useMutation(removeReportsQuery, {
    variables: {
      ids: [deletingId]
    },
    refetchQueries: [
      {
        query: getReportsQuery
      }
    ]
  });

  const [updateReport, { loading: updateLoading }] = useMutation(updateReportQuery, {
    variables: {
      id: editReport?.id,
      name: editReport?.name,
      notes: editReport?.notes
    },
    refetchQueries: [
      {
        query: getReportsQuery
      }
    ]
  });

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', width: 290 },
    { field: 'notes', headerName: 'Notes', width: 260 },
    {
      field: 'view',
      width: 120,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (<></>),
      renderCell: (params: GridRenderCellParams) => {
        return <>
          <Tooltip
            title="Edit report details."
            onClick={(e) => {
              e.stopPropagation();
              setEditReport({
                id: params?.row?.id,
                name: params?.row?.name,
                notes: params?.row?.notes
              } as Report);
            }}
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            key={params.id}
            title="Delete report."
            onClick={(e) => {
              e.stopPropagation();
              setDeletingId(params.row.id);
            }}
          >
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>;
      }
    }
  ];

  return <Layout
    options={
      <>
        <Tooltip title="Add report.">
          <IconButton
            style={{ color: 'white' }}
            component="label"
          >
            <AddIcon />
            <input
              onChange={() => {
                var input = document.getElementById('uploader') as any;

                var data = new FormData();
                data.append('file', input.files[0]);

                fetch('/api/reports/upload', {
                  method: 'POST',
                  body: data
                }).then(() => {
                  refetch();
                });
              }}
              id="uploader"
              type="file"
              accept=".trdx,.trdp"
              hidden
            />
          </IconButton>
        </Tooltip>
      </>
    }
    title="Reports"
  >
    {
      bigScreen ?
        <div style={{
          justifyContent: 'center',
          display: 'flex',
          height: '100%',
          flexDirection: "row"
        }}>
          <DataGrid
            style={{
              maxWidth: '750px',
              maxHeight: '500px',
              marginTop: '20px'
            }}
            sx={{
              cursor: 'pointer',
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
            }}
            onSelectionModelChange={(selection) => {
              history.push('/reports/' + selection[0].toString())
            }}
            rows={data?.reports ?? [] as Report[]}
            columns={columns}
          />
        </div> :
        <>
          <List>
            {
              data?.reports.map((report: Report, index: number) => {
                return <ListItemButton key={index}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1
                      }}
                      onClick={() => history.push('/reports/' + report.id)}
                    >
                      <Typography>{report.name}</Typography>
                      <Typography variant="caption" display="block" gutterBottom>
                        {report.notes}
                      </Typography>
                    </div>
                    <Tooltip
                      title="Edit report details."
                    >
                      <IconButton
                        onClick={(e) => {
                          setEditReport({
                            id: report?.id,
                            name: report?.name,
                            notes: report?.notes
                          } as Report);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </ListItemButton>
              })
            }
          </List>
        </>
    }
      <Dialog open={editReport !== null}>
        <DialogTitle>Edit Report Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edit report details below then click Save.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            fullWidth
            defaultValue={editReport?.name}
            variant="standard"
            onChange={(e) => {
              setEditReport({ id: editReport?.id ?? "", notes: editReport?.notes ?? "", name: e.target.value ?? "" })
            }}
          />
          <TextField
            margin="dense"
            label="Notes"
            fullWidth
            variant="standard"
            defaultValue={editReport?.notes}
            onChange={(e) => {
              setEditReport({ id: editReport?.id ?? "", name: editReport?.name ?? "", notes: e.target.value ?? "" })
            }}
          />
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={updateLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditReport(null)}>Cancel</Button>
          <Button
            onClick={() => {
              updateReport().then(() => {
                setEditReport(null);
              })
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    <Dialog
      open={deletingId !== null}
      onClose={() => {
        setDeletingId(null);
      }}
    >
      <DialogTitle>Delete Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this report?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setDeletingId(null);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            setDeletingId(null);
            deleteReports();
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  </Layout>;
}

export default ReportsPage;
