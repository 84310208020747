import { gql } from "@apollo/client";

export const getSchoolsOnBookQuery = gql`
  query GetSchoolsOnBook($id: String!) {
    book(id: $id) {
      id
      notes {
        id
        schoolId
        schoolName
      }
    }
  }
`;
