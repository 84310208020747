import { useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItemButton, Tooltip, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import SchoolGroupDTO from '../../../models/SchoolGroupDTO';
import { getSchoolGroups } from '../../../queries/get/getSchoolGroups';
import { GetSchoolGroups, GetSchoolGroups_schoolGroups } from '../../../queries/get/types/GetSchoolGroups';
import { addSchoolGroupQuery } from '../../../queries/mutations/addSchoolGroup';
import { removeSchoolGroupsQuery } from '../../../queries/mutations/removeSchoolGroups';
import { Layout } from '../../Layout';

function SchoolGroupsPage() {
  const [deletingId, setDeletingId] = useState<string | null>(null);
  const bigScreen = useMediaQuery('(min-width:600px)');
  const history = useHistory();
  const { data } = useQuery<GetSchoolGroups>(getSchoolGroups);

  const [deleteSchoolGroups] = useMutation(removeSchoolGroupsQuery, {
    variables: {
      schoolGroupIds: [deletingId]
    },
    refetchQueries: [
      {
        query: getSchoolGroups
      }
    ]
  });

  const [addSchoolGroup] = useMutation(addSchoolGroupQuery, {
    refetchQueries: [
      {
        query: getSchoolGroups
      }
    ],
    onCompleted: (e) => {
      history.push("/schools/" + e.addSchoolGroup.id);
    }
  });

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', width: 350 },
    { field: 'shortName', headerName: 'Known As', width: 260 },
    {
      field: 'edit',
      width: 60,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (<></>),
      renderCell: (params: GridRenderCellParams) => {
        return <>
          <Tooltip
            key={params.id}
            title="Delete School Group."
            onClick={(e) => {
              e.stopPropagation();
              setDeletingId(params.row.id);
            }}
          >
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>;
      }
    }
  ];

  return <>
    <Layout
      options={
        bigScreen ? <></> : <>
          <Tooltip title="Add School Group.">
            <IconButton
              style={{ color: 'white' }}
              onClick={() => addSchoolGroup()}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
          {
            deletingId !== null ?
            <Tooltip title="Delete Shool Groups.">
                <IconButton style={{ color: 'white' }} onClick={() => {
                  setDeletingId(null);
                  deleteSchoolGroups();
                }}>
                <DeleteIcon />
              </IconButton>
            </Tooltip> :
            <></>
          }
        </>
      }
      title="School Groups"
    >
      {
        bigScreen ?
          <div style={{
            justifyContent: 'center',
            display: 'flex',
            flexDirection: "row"
          }}>
            <Box sx={{ width: '100%', maxWidth: '750px', mt: 2 }}>
              <Button 
                startIcon={<AddIcon />} 
                onClick={() => addSchoolGroup()}
                variant="contained"
              >
                Add School Group
              </Button>
              <DataGrid
                style={{
                  maxHeight: '500px',
                  marginTop: '20px'
                }}
                rows={data?.schoolGroups ?? [] as SchoolGroupDTO[]}
                columns={columns}
                onSelectionModelChange={(selection) => {
                  history.push('/schools/' + selection[0].toString())
                }}
              />
            </Box>
          </div> :
          <>
            <List>
              {
                data?.schoolGroups.map((schoolGroup: GetSchoolGroups_schoolGroups, index: number) => {
                  return <ListItemButton onClick={() => history.push('/schools/' + schoolGroup.id)} key={index}>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '100%'
                    }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography>{schoolGroup.name}</Typography>
                        <Typography variant="caption" display="block" gutterBottom>
                          {schoolGroup.shortName}
                        </Typography>
                      </div>
                      <div>

                      </div>
                    </div>
                  </ListItemButton>
                })
              }
            </List>
        </>
      }
    </Layout>
    <Dialog
      open={deletingId !== null}
      onClose={() => {
        setDeletingId(null);
      }}
    >
      <DialogTitle>Delete Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this school group?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setDeletingId(null);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            setDeletingId(null);
            deleteSchoolGroups();
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  </>;
}

export default SchoolGroupsPage;
