import { ApolloClient, InMemoryCache } from '@apollo/client';

export const client = new ApolloClient({
  uri: '/graphql/',
  cache: new InMemoryCache({
    typePolicies: {
      transactions: {
        keyFields: ["id"]
      }
    }
  }),
  credentials: 'include'
});
