import { gql } from "@apollo/client";

export const getAuthors = gql`
  query authors {
    authors
  }
`;

export const getPublishers = gql`
  query publishers {
    publishers
  }
`;

export const getIllustrators = gql`
  query illustrators {
    illustrators
  }
`;
