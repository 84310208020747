import { useMutation, useQuery } from '@apollo/client';
import { Alert, Button, Card, CardContent, Checkbox, Divider, FormControlLabel, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import UserDTO from '../../../models/UserDTO';
import { getUser } from '../../../queries/get/getUser';
import { getUsers } from '../../../queries/get/getUsers';
import { setPasswordQuery } from '../../../queries/mutations/setPassword';
import { updateUserQuery } from '../../../queries/mutations/updateUser';
import { Layout } from '../../Layout';

function EditUserPage(props: any) {
  const { loading, data } = useQuery<UserDTO>(getUser, {
    variables: {
      id: props?.match?.params?.id ?? ""
    }
  });

  return <Layout
    backButton={true}
    options={
      <Button disabled style={{ color: 'white' }}>Changes Auto-Save</Button>
    }
  >
    {
      loading ? <>
          <p>Loading</p>
        </> :
        <>
          <div style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            marginTop: "20px"
          }}>
            <Card style={{
              height: "fit-content",
              width: '100%',
              maxWidth: '400px'
            }}>
              <CardContent>
                <EditDetails user={data ?? {} as UserDTO}></EditDetails>
              </CardContent>
            </Card>
          </div>
        </>
    }
  </Layout>;
}

interface MutationResult {
  success: boolean;
}
interface SetPassword {
  setPassword: MutationResult;
}

function EditDetails(props: any) {
  const [user, setUser] = useState<UserDTO>(props?.user?.user);
  const [newPassword, setNewPassword] = useState<string>("");
  const firstRender = useFirstRender();

  const [updateUser] = useMutation(updateUserQuery, {
    variables: {
      id: user.id,
      username: user.username,
      email: user.email,
      admin: user.isAdmin,
      viewer: user.canView,
      editor: user.canEdit
    },
    refetchQueries: [
      {
        query: getUsers
      }
    ]
  });

  const [setPassword, { data: setPasswordResult }] = useMutation<SetPassword>(setPasswordQuery, {
    variables: {
      username: user.username,
      password: newPassword
    }
  });

  useEffect(() => {
    if (!firstRender) {
      updateUser();
    }
  }, [user]);

  return <div style={{
    display: "flex",
    flexDirection: "column",
  }}>
    <Typography variant='h5' style={{ marginBottom: '20px' }}>{user.username}</Typography>
    <TextField
      defaultValue={user.username}
      label="Username"
      onBlur={(e) => setUser({ ...user, username: e.target.value })}
      variant="outlined"
    />
    <TextField
      defaultValue={user.email}
      label="Email"
      onBlur={(e) => setUser({ ...user, email: e.target.value })}
      variant="outlined"
      style={{
          marginTop: "15px"
      }}
    />
    <FormControlLabel
      label="Admin"
      control={
        <Checkbox
          checked={user.isAdmin}
          onChange={(e) => setUser({ ...user, isAdmin: e.target.checked })}
        />
      }
    />
    <FormControlLabel
      label="Editor"
      control={
        <Checkbox
          checked={user.canEdit}
          onChange={(e) => setUser({ ...user, canEdit: e.target.checked })}
        />
      }
    />
    <FormControlLabel
      label="Viewer"
      control={
        <Checkbox
          checked={user.canView}
          onChange={(e) => setUser({ ...user, canView: e.target.checked })}
        />
      }
    />
    <Divider />
    <Alert
      severity="info"
      style={{
        marginTop: "15px"
      }}
    >
      One upper, one lower, one digit and one special character. Minimum of 8 digits.
    </Alert>
    <TextField
      error={setPasswordResult !== undefined ? (!setPasswordResult!.setPassword!.success) : false}
      value={newPassword}
      label="New Password"
      onChange={(e) => setNewPassword(e.target.value)}
      variant="outlined"
      style={{
        marginTop: "15px"
      }}
      helperText={setPasswordResult !== undefined ? (setPasswordResult!.setPassword!.success ? "That worked! Wonderful!" : "Sorry, try again.") : ""}
    />
    <Button
      style={{
        marginTop: "15px"
      }}
      onClick={() => setPassword()}
    >
      Update Password
    </Button>
  </div>;
}

function useFirstRender() {
  const firstRender = useRef(true);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return firstRender.current;
}

export default EditUserPage;
