import { gql } from "@apollo/client";

export const getReportsQuery = gql`
  query GetReports {
    reports {
      id
      added
      name
      notes
    }
  }
`;
