import { gql } from "@apollo/client";

export const getUser = gql`
  query GetUser($id: String!) {
    user(id: $id) {
      id
      username
      email
      isAdmin
      canEdit
      canView
    }
  }
`;
