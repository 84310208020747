import { useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Backdrop, Button, CircularProgress, Dialog,
  DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItemButton, TextField, Tooltip, Typography, useMediaQuery
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useState } from 'react';
import Stylesheet from '../../../models/Stylesheet';
import { getStylesheetsQuery } from '../../../queries/get/getStylesheets';
import { GetStylesheets } from '../../../queries/get/types/GetStylesheets';
import { removeStylesheetsQuery } from '../../../queries/mutations/removeStylesheets';
import { updateStylesheetQuery } from '../../../queries/mutations/updateStylesheet';
import { Layout } from '../../Layout';

export function StylesheetsPage() {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [editStylesheet, setEditStylesheet] = useState<Stylesheet | null>(null);
  const bigScreen = useMediaQuery('(min-width:600px)');

  const { data, refetch } = useQuery<GetStylesheets>(getStylesheetsQuery);

  const [deleteStylesheets] = useMutation(removeStylesheetsQuery, {
    variables: {
      ids: selectedIds
    },
    refetchQueries: [
      {
        query: getStylesheetsQuery
      }
    ]
  });

  const [updateStylesheet, { loading: updateLoading }] = useMutation(updateStylesheetQuery, {
    variables: {
      id: editStylesheet?.id,
      name: editStylesheet?.name,
      notes: editStylesheet?.notes
    },
    refetchQueries: [
      {
        query: getStylesheetsQuery
      }
    ]
  });

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', width: 280 },
    { field: 'notes', headerName: 'Notes', width: 330 },
    {
      field: 'view',
      width: 60,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (<></>),
      renderCell: (params: GridRenderCellParams) => {
        return <>
          <Tooltip
            title="Edit stylesheet details."
            onClick={(e) => {
              setEditStylesheet({
                id: params?.row?.id,
                name: params?.row?.name,
                notes: params?.row?.notes
              } as Stylesheet);
            }}
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </>;
      }
    }
  ];

  return <Layout
    options={
      <>
        <Tooltip title="Add stylesheet.">
          <IconButton
            style={{ color: 'white' }}
            component="label"
          >
            <AddIcon />
            <input
              onChange={(e) => {
                var input = document.getElementById('uploader') as any;

                var data = new FormData();
                data.append('file', input.files[0]);

                fetch('/api/reports/styles/upload', {
                  method: 'POST',
                  body: data
                }).then(() => {
                  refetch();
                });
              }}
              id="uploader"
              type="file"
              accept=".xml"
              hidden
            />
          </IconButton>
        </Tooltip>
        {
          selectedIds.length > 0 ?
            <Tooltip title="Delete stylesheets.">
              <IconButton style={{ color: 'white' }} onClick={() => {
                setSelectedIds([]);
                deleteStylesheets();
              }}>
                <DeleteIcon />
              </IconButton>
            </Tooltip> :
            <></>
        }
      </>
    }
    title="Stylesheets"
  >
    {
      bigScreen ?
        <div style={{
          justifyContent: 'center',
          display: 'flex',
          flexDirection: "row"
        }}>
          <DataGrid
            style={{
              maxWidth: '750px',
              maxHeight: '500px',
              marginTop: '20px'
            }}
            rows={data?.stylesheets ?? [] as Stylesheet[]}
            columns={columns}
            checkboxSelection
            disableSelectionOnClick={true}
            onSelectionModelChange={(selection) => {
              setSelectedIds(selection.map((e) => e.toString()))
            }}
          />
        </div> :
        <>
          <List>
            {
              data?.stylesheets.map((stylesheet, index) => (
                <ListItemButton key={index}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1
                      }}
                    >
                      <Typography>{stylesheet.name}</Typography>
                      <Typography variant="caption" display="block" gutterBottom>
                        {stylesheet.notes}
                      </Typography>
                    </div>
                    <Tooltip
                      title="Edit stylesheet details."
                    >
                      <IconButton
                        onClick={(e) => {
                          setEditStylesheet({
                            id: stylesheet?.id,
                            name: stylesheet?.name,
                            notes: stylesheet?.notes
                          } as Stylesheet);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </ListItemButton>
              ))
            }
          </List>
        </>
    }
    <Dialog open={editStylesheet !== null}>
      <DialogTitle>Edit Stylesheet Details</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Edit stylesheet details below then click Save.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          defaultValue={editStylesheet?.name}
          variant="standard"
          onChange={(e) => {
            setEditStylesheet({ id: editStylesheet?.id ?? "", notes: editStylesheet?.notes ?? "", name: e.target.value ?? "" })
          }}
        />
        <TextField
          margin="dense"
          label="Notes"
          fullWidth
          variant="standard"
          defaultValue={editStylesheet?.notes}
          onChange={(e) => {
            setEditStylesheet({ id: editStylesheet?.id ?? "", name: editStylesheet?.name ?? "", notes: e.target.value ?? "" })
          }}
        />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={updateLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setEditStylesheet(null)}>Cancel</Button>
        <Button
          onClick={() => {
            updateStylesheet().then(() => {
              setEditStylesheet(null);
            })
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  </Layout>;
}

export default StylesheetsPage;
