import { useMutation, useQuery } from '@apollo/client';
import { Visibility } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import {
  Alert,
  Box,
  Button, ButtonBase, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FilledInput, FormControl, IconButton, Input, InputAdornment, InputLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { AppContext } from '../../../Context';
import Book from '../../../models/Book';
import { getBooks } from '../../../queries/get/getBooks';
import { getBooksCountQuery } from '../../../queries/get/getBooksCount';
import { GetBooksCount } from '../../../queries/get/types/GetBooksCount';
import { addBookQuery } from '../../../queries/mutations/addBook';
import { removeBooksQuery } from '../../../queries/mutations/removeBooks';
import { Layout } from '../../Layout';

interface BooksResult {
  books: Book[];
}

function BooksPage() {
  const { state, dispatch } = useContext(AppContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [searchExpanded, expandSearch] = useState<boolean>(false);
  const bigScreen = useMediaQuery('(min-width:600px)');
  const history = useHistory();

  const [deleteBooks] = useMutation(removeBooksQuery, {
    variables: {
      bookIds: state.bookIds
    },
    refetchQueries: [
      {
        query: getBooks,
        variables: {
          pageSize: state.pageSize,
          pageIndex: state.pageIndex,
          search: state.search
        },
        fetchPolicy: 'network-only'
      },
      {
        query: getBooksCountQuery
      }
    ]
  });

  const [addBook] = useMutation(addBookQuery, {
    refetchQueries: [
      {
        query: getBooks,
        variables: {
          pageSize: state.pageSize,
          pageIndex: state.pageIndex,
          search: state.search
        },
        fetchPolicy: 'network-only'
      },
      {
        query: getBooksCountQuery
      }
    ],
    onCompleted: (e) => {
      history.push("/books/" + e.addBook.id);
    }
  });

  const { data: books, loading: loadingBooks } = useQuery<BooksResult>(getBooks, {
    variables: {
      pageSize: state.pageSize,
      pageIndex: state.pageIndex,
      search: state.search
    }
  });

  const { data: booksCount } = useQuery<GetBooksCount>(getBooksCountQuery);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    dispatch({ type: 'set_page', page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    dispatch({ type: 'set_page_size', pageSize: parseInt(event.target.value, 10) });
    dispatch({ type: 'set_page', page: 0 });
  };

  function clearSearch() {
    dispatch({
      type: "set_search",
      search: ""
    });

    dispatch({
      type: "set_page",
      page: 0
    });

    expandSearch(false);
  }

  return <Layout
    options={
      <>
        {
          bigScreen ? <></> : <>
            <IconButton
              style={{ color: 'white' }}
              onClick={() => expandSearch(true)}
            >
              <SearchIcon />
            </IconButton>
            <Tooltip title="Add book.">
              <IconButton
                style={{ color: 'white' }}
                onClick={() => addBook()}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </>
        }
        {
          state.bookIds.length > 0 && !bigScreen ?
            <Tooltip title={"Delete book" + (state.bookIds.length === 1 ? "." : "s.")}>
              <IconButton style={{ color: 'white' }} onClick={() => {
                setShowModal(true);
              }}>
                <DeleteIcon />
              </IconButton>
            </Tooltip> :
            <></>
        }
      </>
    }
    title="Library Books"
    custom={
      searchExpanded ?
        <FormControl sx={{ m: 1, width: '100%', color: 'white' }} variant="outlined">
          <Input
            autoFocus
            style={{
              color: 'white'
            }}
            value={state.search}
            placeholder="Search books..."
            onChange={(e) => dispatch({ type: "set_search", search: e.target.value })}
            onBlur={(e) => {
              if (state.search === "" || state.search === null) {
                expandSearch(false);
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                {
                  state.search !== "" ?
                    <IconButton
                      onClick={() => clearSearch()}
                    >
                      <ClearIcon style={{ color: 'white' }} />
                    </IconButton> :
                    <SearchIcon style={{ color: 'white' }} />
                }
              </InputAdornment>
            }
          />
        </FormControl> : undefined
    }
  >
    <div style={{
      justifyContent: bigScreen ? 'center' : "initial",
      display: 'flex',
      flexDirection: bigScreen ? "row" : "column",
      padding: bigScreen ? '20px' : '0',
      width: '100%'
    }}>
        {
          bigScreen ?
            <div style={{ maxWidth: '1000px',width: '100%' }}>
              <Box display="flex" justifyContent="space-between">
                <Box display="flex">
                  <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-weight">Search</InputLabel>
                    <FilledInput
                      id="outlined-adornment-weight"
                      value={state.search}
                      placeholder="Search books..."
                      onChange={(e) => dispatch({ type: "set_search", search: e.target.value })}
                      endAdornment={
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                      />
                  </FormControl>
                  {
                    state.search !== "" ?
                      <Box display="flex" flexDirection="column" justifyContent="center">
                        <Button
                          startIcon={<CloseIcon />}
                          sx={{ ml: 1 }}
                          onClick={() => clearSearch()}
                        >
                          Clear Search
                        </Button>
                      </Box> : <></>
                  }
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center">
                  <Button 
                    startIcon={<AddIcon />} 
                    onClick={() => addBook()}
                    sx={{ ml: 1 }}
                    variant="contained"
                  >
                    Add Book
                  </Button>
                </Box>
              </Box>
              {
                loadingBooks ?
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                  </div>
                  :
                  <>
                    <TableContainer component={Paper} style={{ width: '100%' }}>
                      <Table sx={{ width: '100%' }} size="small" aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Title</TableCell>
                            <TableCell>Author</TableCell>
                            <TableCell>Publisher</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            loadingBooks ? <CircularProgress /> :
                              books?.books?.map((book: Book) => {
                                return <TableRow
                                hover
                                  key={book.id}
                                  onClick={() => history.push("/books/view/" + book.id)} 
                                  sx={{ cursor: "pointer" }}
                                >
                                  <TableCell>
                                    <Tooltip title={book.title ?? ''}>
                                      <span>
                                        {book.title !== null && book.title.length > 40 ? (book.title.substring(0, 37).trim() + '...') : book.title}
                                      </span>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell>
                                    <Tooltip title={book.author ?? ''}>
                                      <span>
                                        {book.author !== null && book.author.length > 30 ? (book.author.substring(0, 27).trim() + '...') : book.author}
                                      </span>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell>
                                    <Tooltip title={book.publisher ?? ''}>
                                      <span>
                                        {book.publisher !== null && book.publisher.length > 17 ? (book.publisher.substring(0, 14).trim() + '...') : book.publisher}
                                      </span>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell sx={{ pl: 3 }}>
                                    {
                                      book.status === 'accepted' ?
                                      <Tooltip title="This book has been accepted at the schools that have it.">
                                        <CheckIcon fontSize="small" />
                                      </Tooltip> :
                                      book.status === 'rejected' ?
                                      <Tooltip title="This book has been rejected at the schools that have it.">
                                        <CloseIcon fontSize="small" />
                                      </Tooltip> :
                                      book.status === 'edits' ?
                                      <Tooltip title="This book needs editing or has mixed acceptance.">
                                        <Visibility fontSize="small" />
                                      </Tooltip> : <></>
                                    }
                                  </TableCell>
                                  <TableCell>{book.category}</TableCell>
                                  <TableCell>
                                    {
                                      state.admin || state.editor ?
                                        <IconButton onClick={(e) => {
                                            e.stopPropagation();
                                            history.push("/books/" + book.id);
                                          }}
                                        >
                                          <EditIcon />
                                        </IconButton> : <></>
                                    }
                                    {
                                      state.admin || state.editor ?
                                        <IconButton
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            dispatch({ type: "set_book_ids", bookIds: state.bookIds.concat(new Array(book.id)) });
                                            setShowModal(true);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton> : <></>
                                    }
                                  </TableCell>
                                </TableRow>;
                              })
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      component="div"
                      count={booksCount?.booksCount?.count ?? 0}
                      page={state.pageIndex}
                      onPageChange={handleChangePage}
                      rowsPerPage={state.pageSize}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </>
              }
            </div> :
            <>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {
                  loadingBooks ?
                    <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '40px',
                      marginBottom: '40px'
                    }}>
                      <CircularProgress /> 
                    </div> :
                    books!.books.map((book: Book) => {
                      return <ButtonBase
                        key={book.id}
                        style={{
                          padding: '10px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'row'
                        }}
                        onClick={() => history.push("/books/view/" + book.id)}
                      >
                        <div style={{ flexDirection: 'column', textAlign: 'initial' }}>
                          <Typography style={{ fontSize: '16px' }}>
                            {book.title !== null && book.title.length > 37 ? (book.title.substring(0, 35).trim() + '...') : book.title}
                          </Typography>
                          <Typography style={{ fontSize: '10px' }}>
                            {book.author}
                          </Typography>
                        </div>
                        <div>
                          {
                            (state.admin || state.editor) &&
                            <IconButton onClick={() => history.push("/books/" + book.id)}>
                              <EditIcon />
                            </IconButton>
                          }
                          <IconButton onClick={() => {
                            dispatch({ type: 'set_book_ids', bookIds: new Array(book.id) });
                            setShowModal(true);
                          }}>
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </ButtonBase>
                    })
                }
              </div>
              <TablePagination
                component="div"
                count={booksCount?.booksCount?.count ?? 0}
                page={state.pageIndex}
                onPageChange={handleChangePage}
                rowsPerPage={state.pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
        }
    </div>

    <Dialog
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
    >
      <DialogTitle>Delete Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Alert severity="error">Stop! You are about to delete this book for all users in the system. To remove the book from your school only, edit the book and remove your notes.</Alert>
          <Typography sx={{ mt: 2 }}>
            Are you sure you want to delete this book for all users?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setShowModal(false);
            dispatch({ type: "set_book_ids", bookIds: [] as string[] });
          }}
        >
          Cancel
        </Button>
        <Button
          color="error"
          onClick={() => {
            setShowModal(false);
            dispatch({ type: "set_book_ids", bookIds: [] as string[] });
            deleteBooks();
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  </Layout>;
}

export default BooksPage;
