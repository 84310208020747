import { gql } from "@apollo/client";

export const getBook = gql`
  query GetBook($id: String!) {
    book(id: $id) {
      id
      title
      author
      iSBN
      copyright
      published
      illustrator
      publisher
      category
      isbn: iSBN
      notes {
        id
        schoolId
        schoolName
        quantity
        notes
        status
      }
    }
  }
`;
