import { gql } from "@apollo/client";

export const getSchoolGroup = gql`
  query GetSchoolGroup($id: String!) {
    schoolGroup(id: $id) {
      id
      name
      shortName
      schools {
        id
        name
        shortName
      }
    }
  }
`;
