import { gql } from "@apollo/client";

export const addBookQuery = gql`
mutation addBookQuery {
  addBook {
    id
    title
    author
  }
}
`;
