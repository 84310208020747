import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Backdrop, CircularProgress, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link as DomLink } from 'react-router-dom';

interface Props {
  registerPath: string;
  loginPath: string;
}

const UnathenticatedOptions = (props: Props) => {
  const { registerPath, loginPath } = props;
  const [loading, setLoading] = useState<boolean>(false);

  return (<>
    {
      loading ?
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column' }}
          open={true}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h5" gutterBottom component="div">
            Tuning radio...
          </Typography>
        </Backdrop>
        : <></>
    }
    <ListItem component={DomLink} to={loginPath} onClick={() => setLoading(true)}>
      <ListItemIcon>
        <LoginIcon />
      </ListItemIcon>
      <ListItemText>
        Login
      </ListItemText>
    </ListItem>
    <ListItem component={DomLink} to={registerPath} onClick={() => setLoading(true)}>
      <ListItemIcon>
        <PersonAddIcon />
      </ListItemIcon>
      <ListItemText>
        Register
      </ListItemText>
    </ListItem>
  </>);
}

export default UnathenticatedOptions;
