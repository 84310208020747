import { useQuery } from '@apollo/client';
import { Home } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import MenuIcon from '@mui/icons-material/Menu';
import PeopleIcon from '@mui/icons-material/People';
import SchoolIcon from '@mui/icons-material/School';
import StyleIcon from '@mui/icons-material/Style';
import { AppBar, Backdrop, Box, CircularProgress, IconButton, Toolbar, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link as DomLink } from 'react-router-dom';
import Book from "../books.jpg";
import { AppContext } from '../Context';
import UserPrivileges from '../models/UserPrivileges';
import { getUserPrivilegesQuery } from '../queries/get/getUserPrivileges';
import authService from './api-authorization/AuthorizeService';
import { LoginMenu } from './api-authorization/LoginMenu';

interface LayoutProps {
  children?: any;
  title?: string;
  options?: any;
  backButton?: boolean;
  custom?: any;
}

interface UserResult {
  userPrivileges: UserPrivileges;
}

export function Layout(props: LayoutProps) {
  const [loading] = useState<boolean>(false);
  const history = useHistory();
  const [username, setUsername] = useState<string>("");
  const { state: globalState, dispatch } = useContext(AppContext);
  useQuery<UserResult>(getUserPrivilegesQuery, {
    skip: username === "",
    variables: {
      username: username
    },
    onCompleted: (e) => {
      dispatch({ type: 'set_admin', admin: e!.userPrivileges!.admin });
      dispatch({ type: 'set_editor', editor: e!.userPrivileges!.editor });
      dispatch({ type: 'set_viewer', viewer: e!.userPrivileges!.viewer });
    }
  });
  
  useEffect(() => {
    authService.getUser().then(r => setUsername(r.name));
  })

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, left: open });
    };


  return (
    <>
      {
        loading ?
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', flexDirection: 'column' }}
            open={true}
          >
            <CircularProgress color="inherit" />
            <Typography variant="h5" gutterBottom component="div">
              Fastening seatbelts...
            </Typography>
          </Backdrop> :
          <></>
      }
      <Fragment>
        <SwipeableDrawer
          anchor="left"
          open={state["left"]}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          style={{ 
            overflow: 'hidden',
            overflowY: 'hidden'
          }}
        >
          <Box
            sx={{
              width: 250,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              overflow: 'hidden'
            }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List style={{ padding: '0' }}>
              <div style={{
                position: 'relative',
                height: '150px'
              }}>
                <img alt="" src={Book} style={{ height: '100%', width: '100%' }} />
                <div style={{ 
                  position: 'absolute',
                  width: '100%',
                  bottom: '0',
                  padding: '15px'
                }}>
                  <Typography style={{ color: 'white' }} variant="h5">Parchment</Typography>
                  <Typography style={{ color: 'white' }} variant="subtitle1">by Olive Tree Software</Typography>
                </div>
              </div>
              {
                !globalState.editor && !globalState.viewer ?
                  <ListItem button component={DomLink} to="/">
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                  </ListItem> :
                  <></>
              }
              {
                globalState.viewer || globalState.editor || globalState.admin ?
                  <>
                    <ListItem button component={DomLink} to="/books">
                      <ListItemIcon>
                        <LibraryBooksIcon />
                      </ListItemIcon>
                      <ListItemText primary="Library Books" />
                    </ListItem>
                    <ListItem button component={DomLink} to="/reports">
                      <ListItemIcon>
                        <AssessmentIcon />
                      </ListItemIcon>
                      <ListItemText primary="Reports" />
                    </ListItem>
                  </>: <></>
              }
              {
                globalState.admin ?
                  <ListItem button component={DomLink} to="/schools">
                    <ListItemIcon>
                      <SchoolIcon />
                    </ListItemIcon>
                    <ListItemText primary="Schools" />
                  </ListItem> :
                  <></>
              }
              {
                globalState.admin ?
                  <ListItem button component={DomLink} to="/users">
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                  </ListItem> : <></>
              }
              {
                globalState.admin ?
                  <ListItem button component={DomLink} to="/styles">
                    <ListItemIcon>
                      <StyleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Stylesheets" />
                  </ListItem> : <></>
              }
            </List>
            <List>
              <ListItem button component={DomLink} to="/about">
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <ListItemText primary="About" />
              </ListItem>
              <LoginMenu></LoginMenu>
            </List>
          </Box>
        </SwipeableDrawer>
        <AppBar position="static">
          <Toolbar>
            {
              props.custom === undefined ?
                <>
                  {
                    props.backButton ?
                      <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onMouseDown={() => history.goBack()}
                      >
                        <ArrowBackIcon />
                      </IconButton> :
                      <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onMouseDown={toggleDrawer(true)}
                      >
                        <MenuIcon />
                      </IconButton>
                  }
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    sx={{ mr: 2 }}
                    onMouseDown={() => history.push('/')}
                  >
                    <Home />
                  </IconButton>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    {
                      props.title === undefined ? "Parchment" : props.title
                    }
                  </Typography>
                  {
                    props.options !== undefined ? props.options : <></>
                  }
                </> :
                <>
                  {props.custom}
                </>
            }
          </Toolbar>
        </AppBar>
      </Fragment>
      <Box style={{ overflowY: 'auto' }}>
        {props.children}
      </Box>
    </>
  );
}
