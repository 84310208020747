import { gql } from "@apollo/client";

export const getSchoolsMissingBookQuery = gql`
  query GetSchoolsMissingBook($bookId: String!) {
    schoolsMissingBook(bookId: $bookId) {
      id
      name
      shortName
    }
  }
`;
