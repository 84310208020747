import { useQuery } from "@apollo/client";
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import PersonIcon from '@mui/icons-material/Person';
import {
  Alert, AlertTitle, Box, Card, CardActionArea, CardContent, Divider, Grid, List, ListItem,
  ListItemButton, ListItemIcon, ListItemText, Typography
} from '@mui/material';
import React, { useContext } from "react";
import { useHistory } from "react-router";
import AddedSinceIcon from '../appointment.png';
import { AppContext } from "../Context";
import UserDTO from '../models/UserDTO';
import RejectedBooksIcon from '../no-education.png';
import getUnassignedUsersQuery from '../queries/get/getUnassignedUsers';
import BooksNotInSchoolIcon from '../textbook-cancel.png';
import BooksInSchoolIcon from '../textbook.png';
import { Layout } from './Layout';

export function Home() {
  const history = useHistory();
  const { state } = useContext(AppContext);

  interface Report {
    id: string;
    title: string;
    icon: any;    
  }

  const reportStyle = {
    height: 110,
    width: 110,
    marginTop: 10
  };

  const reports = [
    {
      id: '00000000-0db5-43fc-bfbc-496718e8f0ec',
      title: 'View Books in Our School',
      icon: <img src={BooksInSchoolIcon} alt="" style={reportStyle} />
    },
    {
      id: '11111111-a583-4388-96f8-1f20cf63a444',
      title: 'View Books Not at School',
      icon: <img src={BooksNotInSchoolIcon} alt="" style={reportStyle} />
    },
    {
      id: '22222222-96d5-4b86-802c-c1de512a47af',
      title: 'View Books Added Since',
      icon: <img src={AddedSinceIcon} alt="" style={reportStyle} />
    },
    {
      id: '33333333-71aa-46ff-b459-df183d5741dd',
      title: 'View Rejected Books',
      icon: <img src={RejectedBooksIcon} alt="" style={reportStyle} />
    }
  ] as Report[];

  const styling = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  } as React.CSSProperties | undefined;

  return <Layout>
    {
      (state.admin || state.editor || state.viewer) &&
      <>
    <div style={{
      ...styling,
      padding: '20px',
      height: 'fit-content'
    }}>
      <Card sx={{ height: 150, width: '100%', maxWidth: '400px' }}>
        <CardActionArea onClick={() => history.push('/books')}>
          <CardContent sx={{ p: 4 }}>
            <Grid container>
              <Grid item xs={4}>
                <MenuBookTwoToneIcon sx={{fontSize: "50pt" }} color="primary" />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h6">Search &amp; Edit All Books</Typography>
                <Typography>Click here to search and edit books...</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
    <Divider sx={{ marginTop: 2, marginLeft: '40%', marginRight: '40%', mb: 2 }} variant="middle" />
    <div style={{
      ...styling,  
      padding: '20px'
    }}>
      <Box display='flex' justifyContent='center' gap='20px' flexWrap='wrap'>
      {
        reports.map((r, i) => (
          <Card sx={{ width: 320, height: 220 }} key={i}>
            <CardActionArea onClick={() => history.push(`/reports/${r.id}`)}>
              <CardContent sx={{ p: 4, alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6">{r.title}</Typography>
                {r.icon}
              </CardContent>
            </CardActionArea>
          </Card>
        ))
      }
      </Box>
    </div>
    </>
        }
      {
        !state.admin && !state.editor && !state.viewer ?
        <Box>
          <Box display="flex" justifyContent="center" flexDirection={'row'}>
            <Box>
              <Alert severity="info" sx={{ mt: 3, width: 'fit-content' }}>
                <AlertTitle>Approval Required</AlertTitle>
                Thank-you for registering with Parchment.
                You will not have access to anything until you are approved. <br />
                For approval, please contact us below:
                <br />
                <br />
                <Typography variant="subtitle1">
                  509 Frystown Rd <br />
                  Myerstown, PA 17067 <br />
                  <a href="tel:17179497252">(717) 949-7252</a> <br />
                  <a href="mailto:support@olivetree.software">support@olivetree.software</a>
                </Typography>
              </Alert>
              <Alert severity="success" sx={{ mt: 3, width: 'fit-content' }}>
                <AlertTitle>For Weaverland School Users</AlertTitle>
                Please contact Micah Stauffer for access:
                <br />
                <Typography variant="subtitle1">
                  <a href="tel:17179497252">(717) 949-7252</a> <br />
                  <a href="mailto:support@olivetree.software">support@olivetree.software</a>
                </Typography>
              </Alert>
            </Box>
          </Box>
        </Box> : <></>
      }
      {
        state.admin && <UnassignedUsers></UnassignedUsers>
      }
  </Layout>;
}

interface UsersResult {
  unassignedUsers: UserDTO[];
}

function UnassignedUsers() {
  const { data, loading } = useQuery<UsersResult>(getUnassignedUsersQuery, {
    fetchPolicy: 'no-cache'
  });
  const history = useHistory();

  return <div>
    {
      loading || data?.unassignedUsers?.length === 0 ?
        <></> :
        <Box display="flex" justifyContent="center">
          <Card sx={{ width: 'fit-content' }}>
            <Alert severity="info" style={{ marginBottom: '20px' }}>
              <AlertTitle>Unassigned Users</AlertTitle>
              Tap users below to assign them roles in the system.
            </Alert>
            <List>
              {
                data?.unassignedUsers?.map((user: UserDTO, index: number) => {
                  return <ListItem disablePadding key={index}>
                    <ListItemButton onClick={() => history.push("/users/" + user.id)}>
                      <ListItemIcon>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText primary={user.username} />
                    </ListItemButton>
                  </ListItem>
                })
              }
            </List>
          </Card>
        </Box>
    }
  </div>;
}
