import { gql } from "@apollo/client";

export const getSchoolsQuery = gql`
  query GetSchools {
    schools {
      id
      name
      shortName
    }
  }
`;
