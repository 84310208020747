import React, { createContext, useReducer, Dispatch } from "react";
import {
  reducer,
  Actions
} from "./Reducers";

export type MapType = 'satellite' | 'terrain' | 'roadmap'

export type State = {
  bookCount: number
  pageSize: number
  pageIndex: number
  bookIds: string[]
  search: string
  admin: boolean
  editor: boolean
  viewer: boolean
}

let json = localStorage.getItem("pageIndex");
let pageIndex = JSON.parse(json ?? "{}");

json = localStorage.getItem("pageSize");
let pageSize = JSON.parse(json ?? "{}");

json = localStorage.getItem("bookCount");
let bookCount = JSON.parse(json ?? "{}");

json = localStorage.getItem("search");
let search = JSON.parse(json ?? "{}");

const initialState: State = {
  pageIndex: pageIndex?.page ?? 0,
  bookCount: bookCount?.count ?? 0,
  pageSize: pageSize?.pageSize ?? 10,
  search: search?.search ?? "",
  bookIds: [] as string[],
  admin: false,
  editor: false,
  viewer: false
};

const AppContext = createContext<{
  state: State;
  dispatch: Dispatch<Actions>;
}>({
  state: initialState,
  dispatch: () => null
});

const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };
