import { gql } from "@apollo/client";

export const getStylesheetsQuery = gql`
  query GetStylesheets {
    stylesheets {
      id
      added
      name
      notes
    }
  }
`;
