import { gql } from "@apollo/client";

export const getUsers = gql`
  query GetUsers {
    users {
      id
      username
      email
      isAdmin
      canEdit
      canView
    }
  }
`;
