import { gql } from "@apollo/client";

export const getUnassignedUsersQuery = gql`
  query GetUnassignedUsers {
    unassignedUsers {
      id
      username
      email
    }
  }
`;

export default getUnassignedUsersQuery;
