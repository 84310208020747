import { gql } from "@apollo/client";

export const updateSchoolGroupQuery = gql`
mutation updateSchoolGroupQuery($schoolGroup: SchoolGroupDTOInput!) {
  updateSchoolGroup(schoolGroup: $schoolGroup) {
    id
    name
    shortName
    schools {
      id
      name
      shortName
    }
  }
}
`;
