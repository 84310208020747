import { useMutation, useQuery } from '@apollo/client';
import { Delete } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import SecurityIcon from '@mui/icons-material/Security';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItemButton, Tooltip, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import UserDTO from '../../../models/UserDTO';
import { getUsers } from '../../../queries/get/getUsers';
import { GetUsers } from '../../../queries/get/types/GetUsers';
import { removeUsersQuery } from '../../../queries/mutations/removeUsers';
import { Layout } from '../../Layout';

const iconStyle = {
  color: 'gray',
  marginLeft: '6px',
  fontSize: '20px',
  marginTop: '14px'
}

function UsersPage() {
  const bigScreen = useMediaQuery('(min-width:600px)');
  const [deleting, setDeleting] = useState<string | null>(null);
  const history = useHistory();

  const { data } = useQuery<GetUsers>(getUsers);

  const [deleteUsers, { loading }] = useMutation(removeUsersQuery, {
    variables: {
      userIds: [deleting]
    },
    refetchQueries: [
      {
        query: getUsers
      }
    ],
    onCompleted: () => {
      setDeleting(null);
    }
  });

  const columns: GridColDef[] = [
    { field: 'username', headerName: 'User', width: 130 },
    { field: 'email', headerName: 'Email', width: 200 },
    {
      field: 'isAdmin',
      headerName: 'Admin',
      width: 100,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        return <>
          {
            params.row.isAdmin ?
              <Checkbox disabled checked /> :
              <Checkbox disabled />
          }
        </>;
      }
    },
    {
      field: 'canEdit',
      headerName: 'Editor',
      width: 100,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        return <>
          {
            params.row.canEdit ?
              <Checkbox disabled checked /> :
              <Checkbox disabled />
          }
        </>;
      }
    },
    {
      field: 'canView',
      headerName: 'Viewer',
      width: 100,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        return <>
          {
            params.row.canView ?
              <Checkbox disabled checked /> :
              <Checkbox disabled />
          }
        </>;
      }
    },
    {
      field: 'edit',
      width: 100,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (<></>),
      renderCell: (params: GridRenderCellParams) => {
        return <>
        <Tooltip
          title="Edit user."
          onClick={() => history.push('/users/' + params.row.id)}
        >
          <IconButton>
            <EditIcon />
          </IconButton>
        </Tooltip>
          <Tooltip
            title="Delete user."
            onClick={() => setDeleting(params.row.id)}
          >
            <IconButton>
              <Delete />
            </IconButton>
          </Tooltip>
        </>;
      }
    }
  ];

  return <>
    <Layout
      title="Users"
    >
      {
        bigScreen ?
          <div style={{
            justifyContent: 'center',
            display: 'flex',
            height: '100%',
            flexDirection: "row"
          }}>
          <DataGrid
            style={{
              maxWidth: '750px',
              maxHeight: '500px',
              marginTop: '20px'
            }}
            rows={data?.users ?? [] as UserDTO[]}
            columns={columns}
            disableSelectionOnClick={true}
          />
        </div> :
          <>
            <List>
              {
                data?.users.map((user: UserDTO, index: number) => {
                  return <ListItemButton onClick={() => history.push('/users/' + user.id)} key={index}>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '100%'
                    }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography>{user.username}</Typography>
                        <Typography variant="caption" display="block" gutterBottom>
                          {user.email}
                        </Typography>
                      </div>
                      <div>
                        {
                          user.isAdmin ?
                            <Tooltip title="This user is an admin.">
                              <SecurityIcon sx={iconStyle} /> 
                            </Tooltip> : <></>
                        }
                        {
                          user.canEdit ?
                            <Tooltip title="This user is an editor.">
                              <EditIcon sx={iconStyle} />
                            </Tooltip> : <></>
                        }
                        {
                          user.canView ?
                            <Tooltip title="This user can view books.">
                              <VisibilityIcon sx={iconStyle} />
                            </Tooltip> : <></>
                        }
                      </div>
                    </div>
                  </ListItemButton>
                })
              }
            </List>
        </>
      }
      <Dialog
        open={deleting !== null}
        onClose={() => setDeleting(null)}
      >
        <DialogTitle>
          Delete User
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleting(null)}>Cancel</Button>
          <LoadingButton
            autoFocus
            color="error"
            onClick={() => deleteUsers()}
            loading={loading}
            loadingPosition="start"
            startIcon={<Delete />}
            variant="contained"
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Layout>
  </>;
}

export default UsersPage;
