import { gql } from "@apollo/client";

export const getSchoolGroups = gql`
  query GetSchoolGroups {
    schoolGroups {
      id
      name
      shortName
      schools {
        id
        name
        shortName
      }
    }
  }
`;
