import { useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Card, CardContent, Fab, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import SchoolDTO from '../../../models/SchoolDTO';
import SchoolGroupDTO from '../../../models/SchoolGroupDTO';
import { getSchoolGroup } from '../../../queries/get/getSchoolGroup';
import { getSchoolsQuery } from '../../../queries/get/getSchools';
import { GetSchoolGroup } from '../../../queries/get/types/GetSchoolGroup';
import { updateSchoolGroupQuery } from '../../../queries/mutations/updateSchoolGroup';
import { Layout } from '../../Layout';

function EditSchoolGroupPage(props: any) {
  const history = useHistory();
  const [group, setGroup] = useState<SchoolGroupDTO>({} as SchoolGroupDTO);
  const { loading } = useQuery<GetSchoolGroup>(getSchoolGroup, {
    variables: {
      id: props?.match?.params?.id ?? ""
    },
    onCompleted: (e) => {
      setGroup({
        id: e.schoolGroup.id,
        name: e.schoolGroup.name,
        shortName: e.schoolGroup.shortName,
        schools: e.schoolGroup.schools?.map((s) => ({
          id: s.id,
          name: s.name,
          shortName: s.shortName
        } as SchoolDTO)) ?? [] as SchoolDTO[]
      });
    }
  });
  const [updateSchoolGroup] = useMutation(updateSchoolGroupQuery, {
    onCompleted: () => history.push("/schools"),
    refetchQueries: [getSchoolsQuery]
  });

  return <Layout
    backButton={true}
    options={<>
      <Button onClick={() => updateSchoolGroup({ variables: { schoolGroup: group } })} style={{ color: 'white' }}>Save</Button>
    </>}
  >
    {
      loading || group.id === undefined ? <>
          <p>Loading</p>
        </> :
        <>
          <div style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            marginTop: "20px"
          }}>
            <Card style={{
              height: "fit-content"
            }}>
              <CardContent>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                }}>
                  <TextField
                    value={group.name}
                    label="Name"
                    onChange={(e) => setGroup({ ...group, name: e.target.value })}
                    variant="outlined"
                  />
                  <TextField
                    value={group.shortName}
                    label="Short Name"
                    onChange={(e) => setGroup({ ...group, shortName: e.target.value })}
                    variant="outlined"
                    style={{
                      marginTop: "15px",
                      marginBottom: "15px"
                    }}
                  />
                  <Typography variant="h5">Schools</Typography>
                  <div
                    style={{
                      position: 'relative'
                    }}
                  >
                    <Fab
                      style={{
                        position: 'absolute',
                        top: 5,
                        right: 10
                      }}
                      onClick={() => setGroup({ ...group, schools: group.schools?.concat({ id: Math.random().toString(), name: '' } as SchoolDTO) ?? [{} as SchoolDTO] as SchoolDTO[] })}
                      color="primary"
                      aria-label="add"
                    >
                      <AddIcon />
                    </Fab>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Short Name</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                          group.schools?.map((value: SchoolDTO) => (
                            <TableRow
                              key={value.id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                <TextField
                                  value={value.name}
                                  label="Name"
                                  onChange={(e) => setGroup({ ...group, schools: group.schools!.map((s, i) => group.schools!.indexOf(value) === i ? {...s, name: e.target.value} : s ) })}
                                  variant="outlined"
                                />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <TextField
                                  value={value.shortName}
                                  label="Short Name"
                                  onChange={(e) => setGroup({ ...group, schools: group.schools!.map((s, i) => group.schools!.indexOf(value) === i ? {...s, shortName: e.target.value} : s ) })}
                                  variant="outlined"
                                />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <IconButton onClick={() => setGroup({ ...group, schools: group.schools!.filter((s) => s.id !== value.id) })}>
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
              </div>
              </CardContent>
            </Card>
          </div>
        </>
    }
  </Layout>;
}

export default EditSchoolGroupPage;
