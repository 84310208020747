import { gql } from "@apollo/client";

export const getBooks = gql`
  query GetBooks($pageSize: Int!, $pageIndex: Int!, $search: String) {
    books(pageSize: $pageSize, pageIndex: $pageIndex, search: $search) {
      id
      title
      author
      publisher
      copyright
      status
      category
    }
  }
`;
