import { ApolloProvider } from '@apollo/client';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import React, { Component } from 'react';
import { Route } from 'react-router';
import { client } from './ApolloClient';
import { AboutPage } from './components/About';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import { Home } from './components/Home';
import BooksPage from './components/pages/books';
import EditBookPage from './components/pages/books/edit';
import ViewBookPage from './components/pages/books/view';
import ReportsPage from './components/pages/reports';
import ReportViewerPage from './components/pages/reports/viewer';
import SchoolsPage from './components/pages/schools';
import EditSchoolPage from './components/pages/schools/edit';
import StylesheetsPage from './components/pages/stylesheets';
import UsersPage from './components/pages/users';
import EditUserPage from './components/pages/users/edit';
import { AppProvider } from './Context';
import './custom.css';

export class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <AppProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ApolloProvider client={client}>
            <AuthorizeRoute path='/' component={Home} />
            <AuthorizeRoute path='/schools' component={SchoolsPage} />
            <AuthorizeRoute path='/schools/:id' component={EditSchoolPage} />
            <AuthorizeRoute path='/users' component={UsersPage} />
            <AuthorizeRoute path='/users/:id' component={EditUserPage} />
            <AuthorizeRoute path='/books' component={BooksPage} />
            <AuthorizeRoute path='/books/:id' component={EditBookPage} />
            <AuthorizeRoute path='/books/view/:id' component={ViewBookPage} />
            <AuthorizeRoute path='/about' component={AboutPage} />
            <AuthorizeRoute path='/reports' component={ReportsPage} />
            <AuthorizeRoute path='/reports/:id' component={ReportViewerPage} />
            <AuthorizeRoute path='/styles' component={StylesheetsPage} />
            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
          </ApolloProvider>
        </LocalizationProvider>
      </AppProvider>
    );
  }
}
