import { useEffect, useState } from 'react';
//import 'jquery';
//import '../../../assets/jquery-3.6.0.min.js'
//import '../../../assets/telerikReportViewer-15.1.21.616.min.js';
//import '../../../assets/telerikReportViewer.kendo-15.1.21.616.min.js';
//import '../../../assets/kendo.common.min.css';
//import '../../../assets/kendo.default.min.css';
//import '../../../assets/styles.css';

export const ReportViewer = ({ reportId }) => {
  const [viewer, setViewer] = useState();
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    if (viewer !== null && viewer !== undefined) {
      viewer.reportSource({
        report: reportId,
        reportName: reportId,
        parameters: {
      
        }
      });
    }
  }, [viewer]);

  useEffect(() => {
    setViewer(window.jQuery('#reportViewer1')
    .telerik_ReportViewer({
      serviceUrl: "/api/reports/viewer",
      viewMode: 'PRINT_PREVIEW',
      scaleMode: 'FIT_PAGE',
      scale: 1.0,
      enableAccessibility: false,
      sendEmail: { enabled: false },
      reportSource: {
        report: reportId,
        reportName: reportId,
        parameters: {
      
        }
      },
      error: (e, a) => {
        if(a.includes('Please input valid data')) {
          setMsg('Please select school information on the right-hand side. Then click Preview.');
        }
      },
      renderingBegin: () => setMsg(null),
      parameters: {
        editors: {
          // eslint-disable-next-line no-undef
          singleSelect: "COMBO_BOX",
          // eslint-disable-next-line no-undef
          multiSelect: "COMBO_BOX"
        }
      },
      parametersAreaVisible: true
    }).data("telerik_ReportViewer"));
  }, [reportId]);

  return <>
  {
    msg &&
    <div id="micahsDiv" style={{ position: 'absolute', top: '110px', left: 'calc(50% - 300px)', width: '400px', height: 'fit-content', backgroundColor: 'lightblue', zIndex: 10000, borderRadius: 10, padding: '20px' }}>
      <div>{msg}</div>
    </div>
      }
      <div style={{ height: "100%"}} id="reportViewer1"></div>
  </>;
}

export default ReportViewer;
